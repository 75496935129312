module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NZCGJHXG","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/assets/favicons/favicon-32x32.png","theme_color":"#ffffff","icons":[{"src":"src/assets/favicons/favicon-16x16.ico","sizes":"16x16","type":"image/x-icon"},{"src":"src/assets/favicons/favicon-32x32.ico","sizes":"32x32","type":"image/x-icon"},{"src":"src/assets/favicons/favicon-96x96.ico","sizes":"96x96","type":"image/x-icon"},{"src":"src/assets/favicons/favicon-92x92.png","sizes":"92x92","type":"image/png"},{"src":"src/assets/favicons/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"src/assets/favicons/android-icon-36x36.png","sizes":"36x36","type":"image/png"},{"src":"src/assets/favicons/android-icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"src/assets/favicons/apple-icon-57x57.png","sizes":"57x57","type":"image/png"},{"src":"src/assets/favicons/apple-icon-60x60.png","sizes":"60x60","type":"image/png"},{"src":"src/assets/favicons/android-icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"src/assets/favicons/apple-icon-76x76.png","sizes":"76x76","type":"image/png"},{"src":"src/assets/favicons/android-icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"src/assets/favicons/apple-icon-114x114.png","sizes":"114x114","type":"image/png"},{"src":"src/assets/favicons/apple-icon-120x120.png","sizes":"120x120","type":"image/png"},{"src":"src/assets/favicons/android-icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"src/assets/favicons/apple-icon-152x152.png","sizes":"152x152","type":"image/png"},{"src":"src/assets/favicons/apple-icon-180x180.png","sizes":"180x180","type":"image/png"},{"src":"src/assets/favicons/android-icon-192x192.png","sizes":"192x192","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5e2365db877e2af5f956b570de247182"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
